<template>
  <div class="pt-5">
    <v-row>
      <v-col cols="12" class="d-flex justify-center">
        <v-menu offset-y nudge-bottom="0" auto>
          <template v-slot:activator="{ on, attrs }">
            <v-btn style="border: 1px solid #000000" v-bind="attrs" v-on="on">
              <span style="letter-spacing: 0px">keywords</span>
              <v-icon right>mdi-arrow-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in keywordsList"
              :key="index"
              style="border-bottom: 1px solid #cccccc"
              class="py-1"
            >
              <v-list-item-title>
                <div class="d-flex">
                  <div class="keywordTitle px-4 py-2">{{item.title}}</div>
                  <div class="keyword py-2 px-4">{{item.key}}</div>
                </div>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col cols="12">
        <v-text-field
          label="Subject"
          outlined
          dense
          v-model="emailSubject"
          class="formFields"
          color="#7253CF"
          :disabled="formLoading"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <div>Message Content</div>
        <div>
          <quillEditor v-model="templateBody" :options="editorOption" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import { mapGetters } from "vuex";
import { API_EMAIL_GET_KEYWORDS } from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
export default {
  name: "EditTemplate",
  components: {
    quillEditor,
  },
  data() {
    return {
      emailSubject: "",
      templateBody: "",
      keywordsList:[],
      formLoading: false,
      editorOption: {
        placeholder: "Message Content",
        readOnly: true,
        theme: "snow",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            [{ direction: "rtl" }], // text direction
            ["clean"],
          ],
        },
      },
    };
  },
  created() {
    this.$root.$refs.editTemplate = this;
  },
  computed: {
    ...mapGetters({
      getEmailTemplate: "publicDonationPage/getEmailDetail",
    }),
  },
  watch: {},
  methods: {
    submit() {
      this.$root.$refs.InviteViaMail.storeTemplateDetail(
        this.templateBody,
        this.emailSubject
      );
    },
    editTemplateDetail() {
      console.log(this.getEmailTemplate, "emaildetail");
      this.emailSubject = this.getEmailTemplate.email_subject;
      this.templateBody = this.getEmailTemplate.email_body;
      this.getKeyWords()
    },
    getKeyWords() {
      this.keywordsList=[]
      const successHandler = (res) => {
        console.log(res.data);
        this.keywordsList = res.data.email_template_keyword_list;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["email_type"]="invite_mail"
      formData["keyword_for"] = "Parent"
      Axios.request_GET(
        API_EMAIL_GET_KEYWORDS,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
  },
  mounted() {
    this.editTemplateDetail();
  },
};
</script>
<style scoped>
* {
  font-family: Lato;
}
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}
.quill-editor {
  height: 220px;
  font-family: Lato;
}
.keywordTitle {
  color: #000000;
  border: 2px dashed #2c1963;
  font-size: 17px;
  border-radius: 5px;
}
.keyword {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  /* or 310% */

  color: #2c1963;
}
</style>
